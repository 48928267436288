//This mixin is used in EditSalesOrder.vue
import db from '../components/firebaseInit'
const mixin_CompanyProfile = {
    data() {
        return {
            //Company profile values used to populate PDFs
            company_legal_name: null,
            company_trading_as: null,
            company_reg_number: null,
            company_VAT_number: null,
            company_phone: null,
            company_email: null,
            company_website: null,
            company_physical_address_line_1: null,
            company_physical_address_line_2: null,
            company_physical_address_city: null,
            company_physical_address_zip: null,
            company_physical_address_country: null,
            company_physical_address_province: null,
            company_bank_name: null,
            company_bank_account_num: null,
            company_bank_branch_name: null,
            company_bank_branch_code: null,
            company_logo: null,
            company_is_vattable: null,
            VAT_start_date: null,
            fiscal_year_start: null,
            first_fiscal_year: null,
        }
    },
    created() {
        // Gets company profile from firebase datatbase
        db.collection('company_profile')
            .doc('NVmvKiV1F7os4LCRljSC').get().then(snapshot => {
                const document = snapshot.data()
                //2) Destructure object to get individual property values 
                const {
                    company_legal_name, company_trading_as, company_reg_number,
                    company_VAT_number, company_phone, company_email, company_website,
                    company_physical_address_line_1, company_physical_address_line_2,
                    company_physical_address_city, company_physical_address_zip,
                    company_physical_address_country, company_physical_address_province,
                    company_bank_name, company_bank_account_num, company_bank_branch_name,
                    company_bank_branch_code, company_logo, company_is_vattable, VAT_start_date, fiscal_year_start, first_fiscal_year,
                } = document
                //3) Assign properties to variables that can be used by this component

                this.company_legal_name = company_legal_name
                this.company_trading_as = company_trading_as
                this.company_reg_number = company_reg_number
                this.company_VAT_number = company_VAT_number
                this.company_phone = company_phone
                this.company_email = company_email
                this.company_website = company_website
                this.company_physical_address_line_1 = company_physical_address_line_1
                this.company_physical_address_line_2 = company_physical_address_line_2
                this.company_physical_address_city = company_physical_address_city
                this.company_physical_address_zip = company_physical_address_zip
                this.company_physical_address_country = company_physical_address_country
                this.company_physical_address_province = company_physical_address_province
                this.company_bank_name = company_bank_name
                this.company_bank_account_num = company_bank_account_num
                this.company_bank_branch_name = company_bank_branch_name
                this.company_bank_branch_code = company_bank_branch_code
                this.company_logo = company_logo
                this.company_is_vattable = company_is_vattable
                this.VAT_start_date = VAT_start_date
                this.fiscal_year_start = fiscal_year_start
                this.first_fiscal_year = first_fiscal_year
            })
    }
}

export default mixin_CompanyProfile