<template>
  <v-dialog v-model="bulk_journal_entries_dialog" width="850">
    <v-card class="pa-5">
      <v-card-title class="text-h5" @click="test()">
        Bulk Create Journal Entries
      </v-card-title>
      <v-container class="mt-3">
        <v-row>
          <v-col cols="12" sm="12" md="12">
            <v-alert text type="info" dismissible>
              <v-row>
                <v-col cols="12" sm="12" md="12"
                  ><h4>About This Feature:</h4></v-col
                >
              </v-row>
              <v-row>
                <v-col cols="12" sm="12" md="12">
                  <p>
                    Using the fields below you can assign multiple bank
                    transactions to a specific ledger account.
                  </p>
                  <p>
                    Journal entries will then be automatically created for each
                    transaction.
                  </p></v-col
                >
              </v-row>
            </v-alert>
          </v-col>
        </v-row>
        <!--FORM-->
        <v-form v-model="valid" ref="form">
          <!--Entry Breakdown-->
          <v-row class="my-3">
            <v-col>
              <h3>Entry Breakdown</h3>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="4" sm="4" md="4">
              <h5>Supplier</h5>
            </v-col>
            <v-col cols="4" sm="4" md="4">
              <h5>Ledger Account</h5>
            </v-col>
            <v-col cols="2" sm="2" md="2">
              <h5>VAT Rate</h5>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="4" sm="4" md="4"
              ><!--Supplier Name-->
              <v-autocomplete
                v-model="supplier"
                :items="suppliers"
                item-text="supplier_name"
                return-object
                dense
                outlined
                label="Supplier"
                required
                hint="Optional"
                persistent-hint
              >
              </v-autocomplete
            ></v-col>
            <v-col cols="4" sm="4" md="4">
              <!--Ledger Account-->
              <v-autocomplete
                v-model="ledger_account"
                :items="chart_of_accounts"
                item-text="account_name"
                return-object
                dense
                outlined
                label="Ledger Account"
                required
                :rules="[ledgerRules.required]"
              >
              </v-autocomplete>
            </v-col>
            <!--VAT Rate-->
            <v-col cols="2" sm="2" md="2">
              <v-select
                v-model="vat_rate"
                :items="[0, 15]"
                type="number"
                dense
                outlined
                label="VAT %"
                required
              >
              </v-select>
            </v-col>
          </v-row>
        </v-form>
      </v-container>
      <v-divider></v-divider>
      <v-card-actions class="mt-3">
        <v-spacer></v-spacer>
        <v-btn
          color="#33cc99"
          class="white--text"
          elevation="0"
          :loading="loading"
          :disabled="!valid"
          @click="createBulkJournalEntries()"
        >
          Create Journal Entries ({{ selected_transactions.length }})
        </v-btn>

        <v-btn
          text
          @click="
            $refs.form.reset();
            passDialogChange();
          "
          >Cancel</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import db from "../../../components/firebaseInit";
import {
  getChartOfAccounts,
  getSuppliers,
} from "../data/external_bank_statements";
import { getUser } from "../../Accounting/data/external_journal_entries";
import { createBulkJournalEntries } from "../../Accounting/data/external_bulk_journal_entries";
import mixin_CompanyProfile from "../../../globalActions/mixin_CompanyProfile";
export default {
  name: "BulkCreateJournalEntriesModal",
  props: ["bulk_journal_entries_dialog", "selected_transactions"],
  mixins: [mixin_CompanyProfile],
  data() {
    return {
      valid: false,
      loading: false,
      journal_entry_number: null,
      journal_entry_id: null,
      supplier: { supplier_name: null },
      suppliers: [],
      chart_of_accounts: [],
      ledger_account: null,
      vat_rate: 0,
      line_items: null,
      bank_transaction: {},
      journal_entry_created_by_id: null,
      journal_entry_created_by_name: null,
      journal_entry_count: null,
      // Validation Rules
      amountRules: {
        required: (v) => !!v || "An amount is required",
        min: (v) => v > 0 || "Amount must be greater than 0",
      },
      ledgerRules: {
        required: (v) => !!v || "You must select a ledger",
      },
    };
  },
  created() {
    this.getChartOfAccounts();
    this.getSuppliers();
    this.getJournalEntryCount();
    this.getUser();
  },
  computed: {},
  methods: {
    getChartOfAccounts,
    getSuppliers,
    getUser,
    createBulkJournalEntries,
    test() {
      alert(this.journal_entry_created_by_name);
    },
    validate() {
      this.$refs.form.validate();
    },
    // create VAT entry
    calculateVATAmount() {
      this.vat_amount =
        (this.line_amount * this.vat_rate) / (100 + this.vat_rate);
    },
    // CREATE JOURNAL ENTRIES
    //_______________________________
    getJournalEntryCount() {
      db.collection("general_journal_entry_counter")
        .doc("LVEzU1bhljJ8ZLLz6pC2")
        .onSnapshot((snapshot) => {
          this.journal_entry_count = snapshot.data().journal_entry_count;
        });
    },
    // Close modal after form submission
    passDialogChange() {
      this.$emit("closeDialog", false);
    },
    //  Remove selected transactions from array
    passUnselectTransactions() {
      this.$emit("unselectTransactions", []);
    },
  },
};
</script>
