import db from "../../../components/firebaseInit";
import firebase from "firebase";
import { newDateToISO, firebaseTimestamp } from "../../../composables/external";
import { showSnackbar } from "../../../globalActions/index";

export const createBulkJournalEntries = function () {
    //  Check if all line items are assigned to ledgers
    if (this.$refs.form.validate() && this.ledger_account === null) return;
    const batch = db.batch();
    this.loading = true;
    // Used in accounting logic
    const dateArray = [];
    const entryObjArray = [];
    let uniqueDateArray = [];
    // ACCOUNTING LOGIC
    // Create list of unique dates based on transaction dates
    // Dates will be used to assign entry amounts to their
    // correct ledgers in each "Accounting Totals" period
    const createUniqueDateArray = () => {
        this.selected_transactions.forEach((item) => {
            dateArray.push(item.transaction_date.slice(0, 7));
        });
        const uniqueSet = new Set();
        return uniqueDateArray = dateArray.filter((date) => {
            const duplicate = uniqueSet.has(date);
            uniqueSet.add(date);
            return !duplicate;
        });
    }
    // Create entry line items (One for the selected ledger & one for VAT)
    const createLineItems = (line_amount, vat_rate, transaction_type) => {
        this.line_items = [];
        const vat_amount = (Math.abs(line_amount) * vat_rate) / (100 + vat_rate);
        const vat_ledger =
            transaction_type === "Cash Out"
                ? {
                    account_code: "9010",
                    account_id: "VQG2QlAbXMcjbWAeDwPK",
                    account_name: "VAT Input",
                }
                : {
                    account_code: "9020",
                    account_id: "tN26TJD9AIdH7a6CZrAr",
                    account_name: "VAT Output",
                };
        const mainEntry = {
            entry_amount: (Math.abs(line_amount) - vat_amount).toFixed(4),
            ledger_account: this.ledger_account,
        };
        const VATEntry = {
            entry_amount: vat_amount.toFixed(4),
            ledger_account: vat_ledger,
        };
        this.line_items.push(mainEntry, VATEntry);
        return this.line_items;
    }
    // 1:   loop through selected transactions and create new journal entries
    //      for each one
    const addEntries = () => {
        console.log(uniqueDateArray)
        this.selected_transactions.forEach((transaction) => {
            createLineItems(
                transaction.transaction_amount,
                this.vat_rate,
                transaction.transaction_type
            );
            // ACCOUNTING LOGIC
            // Add selected_transaction details to an array
            // It will be used to calculate ledger and VAT totals
            // so they can be assigned to the correct month
            entryObjArray.push({
                transaction_date: transaction.transaction_date.slice(0, 7),
                main_amount: this.line_items[0].entry_amount,
                account_id: this.line_items[0].ledger_account.account_id,
                vat_amount: this.line_items[1].entry_amount,
                transaction_type: transaction.transaction_type
            })
            // Format journal entry number
            const journalEntryCounter =
                this.journal_entry_count < 1000
                    ? `GJE-${String("00000" + this.journal_entry_count).slice(-4)}`
                    : `GJE-${this.journal_entry_count}`;
            // create a new journal entry
            const entryRef = db.collection("general_journal_entries").doc();
            //    Add entry to database
            batch.set(entryRef, {
                journal_entry_id: entryRef.id,
                journal_entry_number: journalEntryCounter,
                reference_number: null,
                supplier: this.supplier,
                journal_entry_date: transaction.transaction_date,
                line_items: this.line_items,
                journal_entry_created_by_id: this.journal_entry_created_by_id,
                journal_entry_created_by_name: this.journal_entry_created_by_name,
                journal_entry_date_created: newDateToISO(),
                journal_entry_timestamp: firebaseTimestamp(),
                bank_transaction: {
                    transaction_id: transaction.transaction_id,
                    transaction_number: transaction.transaction_number,
                },
                closed_off: false,
            });
            // 3: set bank transaction as allocated
            //    Update bank transaction
            const bankTransactionRef = db
                .collection("bank_transactions")
                .doc(transaction.transaction_id);
            batch.update(bankTransactionRef, {
                journal_entry: {
                    journal_entry_id: entryRef.id,
                    journal_entry_number: journalEntryCounter,
                },
                transaction_allocation_status: `Journal Entry - ${journalEntryCounter}`,
            });

            // 4: increment entry counter
            const journalEntryCount = db
                .collection("general_journal_entry_counter")
                .doc("LVEzU1bhljJ8ZLLz6pC2");
            batch.update(journalEntryCount, {
                journal_entry_count: firebase.firestore.FieldValue.increment(1),
            });
            //  Update journal entry count to keep entry numbers in sync with database
            this.journal_entry_count++;
            //  End of ForEach Loop
        });
    }
    //  ACCOUNTING LOGIC
    const calculateLedgerTotals = () => {
        uniqueDateArray.forEach((date) => {
            //  Loop through the unique dates array
            //  Identify the transactions related to each month
            const transactionsPerMonth = entryObjArray.filter((item) => {
                return item.transaction_date === date;
            });
            // Function used to calculate VAT totals and determine
            // which VAT ledger to update
            const calVATTotal = (type) => {
                return transactionsPerMonth
                    .filter((item) => item.transaction_type === type)
                    .reduce((total, item) => {
                        return total + Math.abs(item.vat_amount);
                    }, 0).toFixed(4);
            };
            //  Calculate the MAIN ledger total
            const mainEntryAmount = transactionsPerMonth.reduce((total, item) => {
                return total + Math.abs(item.main_amount);
            }, 0).toFixed(4);
            const VATInputAmount = calVATTotal("Cash Out");
            const VATOutputAmount = calVATTotal("Cash In");
            const ledgerID = this.ledger_account.account_id
            const mainLedgerRef = db.doc(
                `accounting_totals/${date}/ledger_accounts/${ledgerID}`
            );
            const VATInputRef = db.doc(
                `accounting_totals/${date}/ledger_accounts/VQG2QlAbXMcjbWAeDwPK`
            );
            const VATOutputRef = db.doc(
                `accounting_totals/${date}/ledger_accounts/tN26TJD9AIdH7a6CZrAr`
            );
            // Update Main entry totals per month
            batch.update(mainLedgerRef, {
                monthly_total: firebase.firestore.FieldValue.increment(
                    Math.abs(mainEntryAmount)
                ),
            });
            //  Update VAT Input totals per month
            batch.update(VATInputRef, {
                monthly_total: firebase.firestore.FieldValue.increment(
                    Math.abs(VATInputAmount)
                ),
            });
            console.log(`VAT INPUT -- ${VATInputAmount}`)
            //  Update VAT Output totals per month
            batch.update(VATOutputRef, {
                monthly_total: firebase.firestore.FieldValue.increment(
                    Math.abs(VATOutputAmount)
                ),
            });
            console.log(`VAT Output -- ${VATOutputAmount}`)

        })
    }
    createUniqueDateArray()
    addEntries();
    calculateLedgerTotals();


    batch
        .commit()
        .then(() => {
            // 5: Show success message &close dialog box
            this.loading = false;
            showSnackbar(
                `${this.selected_transactions.length} New journal entries added & reconciled successfully`
            );
            this.$refs.form.reset();
            this.passUnselectTransactions();
            this.passDialogChange();
        })
        .catch((err) => console.log(err));
}